var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{attrs:{"show":_vm.alert.show,"variant":_vm.alert.variant,"fade":""}},[_c('span',{staticClass:"text-light-75 font-weight-bold d-block font-size-lg"},[_vm._v(_vm._s(_vm.alert.message))])]),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$t("PRODUCT_PAGES.TITLE"))+" ")]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(_vm._s(_vm.product_pages.length + " " + _vm.$t("PRODUCT_PAGES.TITLE")))])]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{attrs:{"to":{
                        name: 'product_page_meta',
                        params: { id: 'new' },
                    }}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("PRODUCT_PAGES.NEW")))])],1)],1)]),_c('div',{staticClass:"card-body py-0"},[(_vm.product_pages.length)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center",attrs:{"id":"kt_advance_table_widget_4"}},[_c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"pl-0",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(_vm.$t("PRODUCT_PAGES.NAME"))+" ")]),_c('th',{staticClass:"pr-0 text-right",staticStyle:{"min-width":"160px"}},[_vm._v(" "+_vm._s(_vm.$t("ACTIONS"))+" ")])])]),_c('tbody',_vm._l((_vm.product_pages),function(productPage){return _c('tr',{key:productPage.page_id},[_c('td',{staticClass:"pl-0"},[_c('router-link',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary font-size-lg",attrs:{"to":{
                                        name: 'product_page_meta',
                                        params: {
                                            id: productPage.page_id,
                                        },
                                    }}},[_vm._v(_vm._s(productPage.name)+" "),_c('span',{staticClass:"bold text-primary"},[_vm._v(_vm._s(productPage.isDefault ? " (Standard)" : ""))])]),_c('span',{staticClass:"svg-icon svg-icon-md pl-2 pe-auto",on:{"click":function($event){return _vm.extendProductPage(
                                            productPage.page_id
                                        )}}},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrows-v.svg","title":_vm.$t('PRODUCT_PAGES.EXPAND')}})],1),(productPage.expanded)?_c('div',{class:_vm.row},[_c('span',[_vm._v(_vm._s(_vm.$t("PRODUCT_PAGES.PRODUCTS"))+":")]),(
                                            productPage.relations.length >=
                                            1
                                        )?_c('ul',_vm._l((productPage.relations),function(relation){return _c('li',{key:relation.id},[_c('span',[_vm._v("- "+_vm._s(relation.product_name))])])}),0):_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("PRODUCT_PAGES.NO_PRODUCTS")))])]):_vm._e()],1),_c('td',{staticClass:"pr-0 text-right"},[_c('router-link',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm",attrs:{"to":{
                                        name: 'product_page_meta',
                                        params: {
                                            id: productPage.page_id,
                                            edit: true,
                                        },
                                    }}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Write.svg","title":_vm.$t('EDIT')}})],1)]),_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm mx-3",on:{"click":function($event){return _vm.changeDefault(
                                            productPage.page_id,
                                            !productPage.isDefault
                                        )}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Star.svg","title":_vm.$t('FAVORITE')}})],1)]),_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm",on:{"click":function($event){return _vm.confirmDelete(productPage.page_id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg","title":_vm.$t('DELETE')}})],1)])],1)])}),0)])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }