<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                alert.message
            }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">
                        {{ $t("PRODUCT_PAGES.TITLE") }}
                    </span>
                    <span
                        class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{
                            product_pages.length +
                            " " +
                            $t("PRODUCT_PAGES.TITLE")
                        }}</span
                    >
                </h3>
                <div class="card-toolbar">
                    <router-link
                        :to="{
                            name: 'product_page_meta',
                            params: { id: 'new' },
                        }"
                    >
                        <b-button variant="primary">{{
                            $t("PRODUCT_PAGES.NEW")
                        }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="product_pages.length">
                    <table
                        class="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_4"
                    >
                        <thead>
                            <tr class="text-left">
                                <th class="pl-0" style="min-width: 80px">
                                    {{ $t("PRODUCT_PAGES.NAME") }}
                                </th>
                                <th
                                    class="pr-0 text-right"
                                    style="min-width: 160px"
                                >
                                    {{ $t("ACTIONS") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-bind:key="productPage.page_id"
                                v-for="productPage in product_pages"
                            >
                                <td class="pl-0">
                                    <router-link
                                        :to="{
                                            name: 'product_page_meta',
                                            params: {
                                                id: productPage.page_id,
                                            },
                                        }"
                                        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                        >{{ productPage.name }}
                                        <span class="bold text-primary">{{
                                            productPage.isDefault
                                                ? " (Standard)"
                                                : ""
                                        }}</span>
                                    </router-link>
                                    <span
                                        class="svg-icon svg-icon-md pl-2 pe-auto"
                                        v-on:click="
                                            extendProductPage(
                                                productPage.page_id
                                            )
                                        "
                                    >
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                        <inline-svg
                                            src="media/svg/icons/Navigation/Arrows-v.svg"
                                            :title="$t('PRODUCT_PAGES.EXPAND')"
                                        ></inline-svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <div
                                        v-if="productPage.expanded"
                                        :class="row"
                                    >
                                        <span
                                            >{{
                                                $t("PRODUCT_PAGES.PRODUCTS")
                                            }}:</span
                                        >
                                        <ul
                                            v-if="
                                                productPage.relations.length >=
                                                1
                                            "
                                        >
                                            <li
                                                v-for="relation in productPage.relations"
                                                :key="relation.id"
                                            >
                                                <span
                                                    >-
                                                    {{
                                                        relation.product_name
                                                    }}</span
                                                >
                                            </li>
                                        </ul>
                                        <span v-else class="text-muted">{{
                                            $t("PRODUCT_PAGES.NO_PRODUCTS")
                                        }}</span>
                                    </div>
                                </td>
                                <td class="pr-0 text-right">
                                    <router-link
                                        :to="{
                                            name: 'product_page_meta',
                                            params: {
                                                id: productPage.page_id,
                                                edit: true,
                                            },
                                        }"
                                        class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                                        <span
                                            class="svg-icon svg-icon-md svg-icon-primary"
                                        >
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                            <inline-svg
                                                src="media/svg/icons/Communication/Write.svg"
                                                :title="$t('EDIT')"
                                            ></inline-svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </router-link>
                                    <a
                                        v-on:click="
                                            changeDefault(
                                                productPage.page_id,
                                                !productPage.isDefault
                                            )
                                        "
                                        class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    >
                                        <span
                                            class="svg-icon svg-icon-md svg-icon-primary"
                                        >
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                            <inline-svg
                                                src="media/svg/icons/General/Star.svg"
                                                :title="$t('FAVORITE')"
                                            ></inline-svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                    <a
                                        v-on:click="
                                            confirmDelete(productPage.page_id)
                                        "
                                        class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                                        <span
                                            class="svg-icon svg-icon-md svg-icon-primary"
                                        >
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                            <inline-svg
                                                src="media/svg/icons/General/Trash.svg"
                                                :title="$t('DELETE')"
                                            ></inline-svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Swal from "sweetalert2"
import Vue from "vue"

export default {
    name: "product_pages",
    data() {
        return {
            alert: {
                show: false,
                message: "",
                variant: "",
            },
            brand_id: null,
            product_pages: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("PRODUCT_PAGES.TITLE"), route: "/product_pages" },
        ])
        ApiService.get("product_page_meta")
            .then(({ data }) => {
                this.brand_id = data.brand_id
                this.product_pages = data.product_pages
                    .map((product_page) => {
                        return {
                            ...product_page,
                            expanded: false,
                            relations: [],
                        }
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
            })
            .catch(() => {
                //console.log(error)
            })
    },
    methods: {
        confirmDelete(page_id) {
            const product_page = this.product_pages.find(
                (page) => page.page_id === page_id
            )
            Swal.fire({
                title: this.$t("PRODUCT_PAGE_META.DELETE_TITLE", {
                    name: product_page.name,
                }),
                text: this.$t("PRODUCT_PAGE_META.DELETE_TEXT"),
                icon: "error",
                showCancelButton: true,
                cancelButtonText: this.$t("CANCEL"),
                confirmButtonText: this.$t("DELETE"),
                buttonsStyling: false,
                customClass: {
                    cancelButton: "btn btn-secondary",
                    confirmButton: "btn btn-danger",
                },
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete("product_page_meta/" + page_id)
                        .then(({ data }) => {
                            this.product_pages = data.product_pages
                                .map((product_page) => {
                                    return {
                                        ...product_page,
                                        expanded: false,
                                        relations: [],
                                    }
                                })
                                .sort((a, b) => a.name.localeCompare(b.name))
                            this.showAlert(
                                this.$t("PRODUCT_PAGE_META.DELETE_SUCCESS", {
                                    name: product_page.name,
                                }),
                                "success"
                            )
                        })
                        .catch(() => {
                            this.showAlert(
                                this.$t("PRODUCT_PAGE_META.DELETE_ERROR", {
                                    name: product_page.name,
                                }),
                                "danger"
                            )
                        })
                }
            })
        },
        showAlert(message, variant) {
            Vue.set(this.alert, "show", true)
            Vue.set(this.alert, "message", message)
            Vue.set(this.alert, "variant", variant)
            setTimeout(() => {
                this.alert.show = false
            }, 3000)
        },
        changeDefault(page_id, isDefault) {
            ApiService.put("product_page_meta/" + page_id + "/default", {
                isDefault: isDefault,
            })
                .then(({ data }) => {
                    this.product_pages = data.product_pages
                        .map((product_page) => {
                            return {
                                ...product_page,
                                expanded: false,
                                relations: [],
                            }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                })
                .catch(() => {
                    //console.log(error)
                })
        },
        extendProductPage(page_id) {
            const product_page = this.product_pages.find(
                (page) => page.page_id === page_id
            )
            product_page.expanded = !product_page.expanded
            ApiService.get("product_page_meta/" + page_id)
                .then(({ data }) => {
                    const product_page = this.product_pages.find(
                        (page) => page.page_id === page_id
                    )
                    product_page.relations = data.products
                })
                .catch(() => {
                    //console.log(error)
                })
        },
    },
}
</script>
